@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
    font-family: "PT Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    line-height: 1.2em;
    margin: 0;
    padding: 0;
}
